<template>
  <page-layout>
    <div class="wrapper dashboard">
      <div class="title-wrap">
        <h3 class="page-titles">
          Welcome {{ this.$store.state.USER.user.firstName }}
        </h3>
        <p>Great to have you back! Feel free to explore, or follow these steps and create your campaign.</p>

        <div class="profile">
          <the-profile class="print:hidden" />
        </div>
      </div>

      <div class="rounded-3xl bg-white dashboard-card p-12">
        <div class="flex p-8 mobile-flex justify-between">
          <div class="flex w-1/2">
            <div class="flex flex-col items-center">
              <h5 class="tab-view-icon px-3 py-1">
                <span>1</span>
              </h5>
              <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>2</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>3</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>4</span>
              </h5>
            </div>
            <div class="flex flex-col">
              <div class="dashboard-card-steps lite-gray md:mt-5 lg:mt-5">
                <h3 class="font-semibold">
                  Create campaign
                </h3>
                <p>
                  Start with selecting the tests you prefer and create a campaign.
                </p>
              </div>
              <div class="dashboard-card-steps mx-2 sm:mt-2 md:mt-11 lg:mt-11 lite-gray">
                <h3 class="font-semibold">
                  Distribute tests
                </h3>
                <p>
                  Copy the link and distribute unlimited tests in your selected channels.
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11 lite-gray">
                <h3 class="font-semibold">
                  Specify cognitive requirements
                </h3>
                <p>
                  Specify your own requirements to better assess candidates.
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11   lite-gray">
                <h3 class="font-semibold">
                  Analyze, match and select
                </h3>
                <p>
                  Sort & filter candidates in scoreboard. Analyze & match candidates.
                  You can also talk to cognitive experts for a deeper understanding.
                </p>
              </div>
            </div>
            <!-- <button v-popover:tooltip="'This is a string value'">
              My butt0n
            </button>
            <tooltip  /> -->
          </div>
          <div class="illustration flex flex-col items-center w-2/5">
            <img
              src="@/assets/capacio/dashboard/dashboard-img.svg"
            >
            <router-link
              class="btn-primary dashboard-create mt-5"
              :to="{name: 'CreateCampaign'}"
            >
              Create campaign
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout.vue'
// import LinkCard from '../components/LinkCard.vue'
import TheProfile from '@/components/TheProfile.vue'
export default {
  components: {
    // LinkCard,
    PageLayout,
    TheProfile
  }
}
</script>

<style lang="scss">
@import "../dashboard.scss";
</style>
